/**
 * External Dependencies
 */
import 'jquery';
// import 'custom-event-polyfill';

import lozad from 'lozad';
import 'slick-carousel/slick/slick.min';
import tippy from 'tippy.js';
import { getCookie } from './components/cookie';
import 'select2/dist/js/select2';
import '@fancyapps/fancybox';
// import axios from 'axios/dist/axios';
import 'sticky-sidebar/dist/jquery.sticky-sidebar';
import MagicGrid from 'magic-grid';

// import Tablesaw from 'tablesaw/dist/tablesaw';
// import Parallax from 'parallax-js'
// import 'jquery-parallax.js/parallax';
// import simpleParallax from 'simple-parallax-js';

$(document).ready(() => {
  //tippy
  tippy('[data-tippy-content]');

  if ( $('[data-fancybox]').length ) {
    $('[data-fancybox]').fancybox({
      clickSlide: false,
      clickOutside: false,
      touch: false,
    });
  }

  $('.js-select').select2({
    templateSelection: function (data) {
      // console.log(data);
      if (data.text !== '') {
        return data.text;
      }
    },
  });


  if ( $('.sticky-sb').length ) {
    let postImages;

    const getPostImagesArray = () => {
      postImages = [];

      $('.wp-block-image.alignwide, .wp-block-table.alignwide, .cta-section-blog').each(function() {
        const imageHeight = $(this).outerHeight();
        const imageCordTop = $(this).offset().top;
        const imageCordBottom = imageCordTop + imageHeight;

        postImages.push({
          startCord: imageCordTop,
          endCord: imageCordBottom,
          imgHeight: imageHeight,
        });
      });

      console.log(postImages);
    }

    getPostImagesArray();


    $('.sticky-sb').stickySidebar({
      topSpacing: 110,
      bottomSpacing: 0,
    });

    $(window).scroll(() => {
      const stickySbHeight = $('.sticky-sb__group').outerHeight();
      const sbCordTop = $('.sticky-sb__group').offset().top;
      const sbCordBottom = sbCordTop + stickySbHeight + 50;

      if ( sbCordTop % 2500 < 50 ) {
        getPostImagesArray();
      }

      // console.log( sbCordTop, sbCordBottom )
      let isScrolledOut = true;

      postImages.forEach(function(item) {
        if ( item.startCord <= sbCordBottom && item.endCord >= sbCordTop ) {
          $('.sticky-sb').addClass('is-hidden');
          isScrolledOut = false
          return false;
        }

        if ( isScrolledOut ) {
          console.log(true);
          $('.sticky-sb').removeClass('is-hidden');
        }
      });

    });
  }

  function preventDefault(e){
    e.preventDefault();
  }

  function disableScroll() {
      document.body.addEventListener('touchmove', preventDefault, { passive: false });
  }
  function enableScroll() {
      document.body.removeEventListener('touchmove', preventDefault);
  }


  //parallax
  // const scene = document.getElementById('scene');
  // if ( $(scene).length ) {
  //   const parallaxInstance = new Parallax(scene);
  // }

  // const plannet1 = $('.planet-1');
  // const plannet2 = $('.planet-2');
  // $(window).scroll(function() {
  //   const scrollTop = $(window).scrollTop();

  //   plannet1.css({
  //     transform: `translateY( ${ scrollTop * 0.1 }%) translateX(-${ scrollTop * 0.005 }%)`,
  //   });

  //   plannet2.css({
  //     transform: `translateY( ${ scrollTop * 0.2 }%) rotate(${ scrollTop * 0.01 }deg)`,
  //   });
  // });

  if ( $('.js-testimonials').length ) {
    $('.js-testimonials').slick({
      infinite: true,
      fade: true,
      dots: true,
      lazyLoad: 'ondemand',
      prevArrow: '<button type="button" class="slick-prev"><svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.1464 29.6465C9.95118 29.8417 9.95118 30.1583 10.1464 30.3536L13.3284 33.5355C13.5237 33.7308 13.8403 33.7308 14.0355 33.5355C14.2308 33.3403 14.2308 33.0237 14.0355 32.8284L11.2071 30L14.0355 27.1716C14.2308 26.9763 14.2308 26.6597 14.0355 26.4645C13.8403 26.2692 13.5237 26.2692 13.3284 26.4645L10.1464 29.6465ZM50.5 29.5L10.5 29.5L10.5 30.5L50.5 30.5L50.5 29.5Z" fill="white"/></svg></button>',
      nextArrow: '<button type="button" class="slick-next"><svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M50.3536 30.3536C50.5488 30.1583 50.5488 29.8417 50.3536 29.6464L47.1716 26.4645C46.9763 26.2692 46.6597 26.2692 46.4645 26.4645C46.2692 26.6597 46.2692 26.9763 46.4645 27.1716L49.2929 30L46.4645 32.8284C46.2692 33.0237 46.2692 33.3403 46.4645 33.5355C46.6597 33.7308 46.9763 33.7308 47.1716 33.5355L50.3536 30.3536ZM10 30.5H50V29.5H10V30.5Z" fill="white"/></svg></button>',
    });
  }

  if ( $('.js-dates-slider').length ) {
    $('.js-dates-slider').show().slick({
      infinite: true,
      // fade: true,
      dots: false,
      prevArrow: '<button type="button" class="slick-prev"><svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.00195312 7.99707C0.00195312 8.32227 0.125 8.59473 0.388672 8.84961L6.80469 15.125C7.00684 15.3359 7.26172 15.4414 7.56055 15.4414C8.16699 15.4414 8.65039 14.958 8.65039 14.3516C8.65039 14.0527 8.52734 13.7803 8.31641 13.5693L2.59473 7.99707L8.31641 2.4248C8.52734 2.21387 8.65039 1.93262 8.65039 1.64258C8.65039 1.03613 8.16699 0.552734 7.56055 0.552734C7.26172 0.552734 7.00684 0.658203 6.80469 0.869141L0.388672 7.13574C0.125 7.39941 0.0107422 7.66309 0.00195312 7.99707Z" fill="black"/></svg></button>',
      nextArrow: '<button type="button" class="slick-next"><svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.98926 7.99707C8.98047 7.66309 8.86621 7.39941 8.60254 7.13574L2.18652 0.869141C1.98438 0.658203 1.72949 0.552734 1.43066 0.552734C0.824219 0.552734 0.34082 1.03613 0.34082 1.64258C0.34082 1.93262 0.463867 2.21387 0.674805 2.4248L6.39648 7.99707L0.674805 13.5693C0.463867 13.7803 0.34082 14.0527 0.34082 14.3516C0.34082 14.958 0.824219 15.4414 1.43066 15.4414C1.72949 15.4414 1.98438 15.3359 2.18652 15.125L8.60254 8.84961C8.86621 8.59473 8.98926 8.32227 8.98926 7.99707Z" fill="black"/></svg></button>',
    });

    // $('.js-dates-slider').on('init', function(event, slick){
    const startSlideVal = $('.js-dates-slider [data-slick-index="0"]').text();

    $('.js-e-date').val(startSlideVal);
      // console.log(slideVal);
    // });
    $('.js-dates-slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
      const slideVal = $(`.js-dates-slider [data-slick-index="${ currentSlide }"]`).text();
      $('.js-e-date').val(slideVal);
      // console.log(slideVal);
    });
  }
  // $('.img-parallax').each(function(){
  //   var img = $(this);
  //   var imgParent = $(this).parent();
  //   function parallaxImg () {
  //     var speed = img.data('speed');
  //     var imgY = imgParent.offset().top;
  //     var winY = $(this).scrollTop();
  //     var winH = $(this).height();
  //     var parentH = imgParent.innerHeight();


  //     // The next pixel to show on screen
  //     var winBottom = winY + winH;

  //     // If block is shown on screen
  //     // if (winBottom > imgY && winY < imgY + parentH) {
  //       // Number of pixels shown after block appear
  //       var imgBottom = ((winBottom - imgY) * speed);
  //       // Max number of pixels until block disappear
  //       var imgTop = winH + parentH;
  //       // Porcentage between start showing until disappearing
  //       var imgPercent = ((imgBottom / imgTop) * 100) + (50 - (speed * 50));
  //       console.log(parentH);
  //     // }
  //     img.css({
  //       top: imgPercent + '%',
  //       transform: 'translate(-50%, -' + imgPercent + '%)',
  //     });

  //     // console.log(img);
  //   }
  //   $(document).on({
  //     scroll: function () {
  //       parallaxImg();
  //     }, ready: function () {
  //       parallaxImg();
  //     },
  //   });
  // });



  //lazy loading
  // const pictureObserver = lozad('.lozad-picture', {
  //   threshold: 0.1,
  //   loaded: function(el) {
  //     // Custom implementation on a loaded element
  //     let elClassList = el.classList.value;
  //     if ( elClassList.includes('video-obj') && $('.section-v-slider').length ) {
  //       id = el.id;
  //       playVideoSlider(id);
  //     }
  //   },
  // });

  // const images = document.getElementsByClassName('lozad');
  // for (const image of images) {
  //   if (!image.getAttribute('data-background-image')) {
  //     image.onload = function() {
  //       this.setAttribute('data-fully-loaded', true);
  //     };
  //   }
  // }

  //scroll anchors
  $(document).on('click', 'a[href*="#"]', function (event) {
    var that = $(this);
    if ( that[0].hasAttribute('data-fancybox') || that.hasClass('no-scroll') ) {
      console.log('false');
      return false;
    } else {
      var url = that.attr('href');
      var hash = url.split('#')[1];
      hash = '#' + hash;

      $('.hamburger').removeClass('is-active');
      $('html').removeClass('is-menu-opened');
      disableScroll();
      $('.mobile-menu').slideUp();

      if ( $(hash).length ) {
        event.preventDefault();
        var scrollTop = $(hash).offset().top - 100;
        $('html, body').animate({
          scrollTop: scrollTop,
        }, 500);
      }
    }
  });

  // lazy loading
  const observer = lozad('.lozad', {
    rootMargin: '150px 0px',
    threshold: 0.1,
  });
  observer.observe();
  // pictureObserver.observe();

  function copyToClipboard(text) {
    if (window.clipboardData && window.clipboardData.setData) {
      // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
      return window.clipboardData.setData("Text", text);

    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
      var textarea = document.createElement("textarea");
      textarea.textContent = text;
      textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        return document.execCommand("copy");  // Security exception may be thrown by some browsers.
      }
      catch (ex) {
        console.warn("Copy to clipboard failed.", ex);
        return false;
      }
      finally {
        document.body.removeChild(textarea);
      }
    }
}

  //copy referral link
  $(document).on('click', '.js-referral', e => {
    const that = $(e.currentTarget);
    const referralLink = that.attr('data-ref');
    console.log(e);
    that.text('Copied!');
    copyToClipboard(referralLink);
    setTimeout(() => {
      that.text('Copy referral link');
    }, 3000);
  });

  //testimonials
  $(document).on('click', '.testimonials-2-box.is-clickable .testimonials-2-box__group', e => {
    // if ( $(window).width() > 1199 ) {
      // $('.bio').removeClass('is-active');
    const that = $(e.currentTarget);
    const bio = that.siblings('.bio');
    $.fancybox.open(bio, {
      touch: false,
    });

    // }
  });

  $(document).on('click', '.bio .close', e => {
    const that = $(e.currentTarget);
    that.closest('.bio').removeClass('is-active');
  });

  //click outside bio
  $(document).click(function(event) {
    const $target = $(event.target);

    if( !$target.closest('.testimonials-2-box.is-clickable').length ) {
      $('.testimonials-2-box.is-clickable .bio').removeClass('is-active');
    }
  });

  // if ( $('.masthead-2').length ) {
  //   $(window).on('scroll', () => {
  //     if ( $(window).scrollTop() > 0 ) {
  //       $('.masthead-2').addClass('is-scrolled');
  //     } else {
  //       $('.masthead-2').removeClass('is-scrolled');
  //     }
  //   });
  // }

  $(document).on('click', '.hamburger', e => {
    const that = $(e.currentTarget);
    that.toggleClass('is-active');
    $('html').toggleClass('is-menu-opened');
    $('.mobile-menu').slideToggle();

    if ( $('html').hasClass('is-menu-opened') ) {
      disableScroll();
    } else {
      enableScroll()
    }
  });

  //material input
  $(document).on('focus input', '.input-group--2 input', function() {
    $(this).siblings('label').addClass('is-active');
  });

  $(document).on('blur', '.input-group--2 input', function() {
    $('.input-group--2 input').each(function() {
      if ($(this).val() != '') {
        $(this).siblings('label').addClass('is-active');
      } else {
        $(this).siblings('label').removeClass('is-active');
      }
    });
  });

  $('.input-group--2 input').each(function() {
    if ($(this).val() != '') {
      $(this).siblings('label').addClass('is-active');
    }
  });

  const clarityUserIdLocal = localStorage.getItem('clarityID');

  const waitFor = (variable, callback) => {
    const interval = setInterval(function() {
      const clarityId = getCookie(variable);
      if ( clarityId != '' ) {
        clearInterval(interval);
        callback();
      }
    }, 1000);
  };

  waitFor('_clck', () => {
    let clarityUserId = getCookie('_clck');

    if ( clarityUserId != clarityUserIdLocal && window.dataLayer ) {
      window.dataLayer.push({
        'event': 'clarityVisitor',
        'id': clarityUserId,
      });
      localStorage.setItem('clarityID', clarityUserId);
      console.log('new visitor!', clarityUserId);
    }
  });

  $(document).on('input', '.js-tel', e => {
    let phone = $(e.currentTarget).val();
    phone = phone.replace(/[^0-9+]/g, '');
    $(e.currentTarget).val(phone);
  });

  //hs form
  //employee form
  $('.js-meeting-form2').on('submit', function() {
    const form = $(this);
    const hsListId = form.find('.js-hs-id3').val();
    const tyBox = form.siblings('.js-ty-box2');

    let fields = [];

    form.find('.input-group, .select-group').each(function() {
      const thatInput = $(this).find('input, textarea, select');

      fields.push({
        name: thatInput.attr('name'),
        value: thatInput.val(),
      });
    });

    if ( hsListId ) {

      $.ajax({
        url: `https://api.hsforms.com/submissions/v3/integration/submit/20247445/${hsListId}`,
        method: 'post',
        contentType: 'application/json',
        dataType: 'JSON',
        data: JSON.stringify({
          "submittedAt": Date.now(), // This millisecond timestamp is optional. Update the value from "1517927174000" to avoid an INVALID_TIMESTAMP error.
          "fields": fields,
          "context": {
            // "hutk": hutk,
            "pageUri": window.location.href,
            "pageName": $('head title').text(),
            "ipAddress": window.userIP,
          },
        }),
      })
      .done(function() {
        form.addClass('hidden');
        tyBox.removeClass('hidden');
      })
      .fail(function(e) {
        alert('Something went wrong, please conctact with website administrator');
        console.log(e);
      });

    }

    return false;
  });

  $('.js-f-subscription').on('submit', function() {
    const form = $(this);
    const hsListId = '268c379a-41a9-47ef-87d9-712c92091454';
    const tyBox = form.closest('.f-signup-box').find('.f-ty-message');

    const emailField = form.find('input[type="email"]').val();

    if ( hsListId ) {

      $.ajax({
        url: `https://api.hsforms.com/submissions/v3/integration/submit/20247445/${hsListId}`,
        method: 'post',
        contentType: 'application/json',
        dataType: 'JSON',
        data: JSON.stringify({
          "submittedAt": Date.now(), // This millisecond timestamp is optional. Update the value from "1517927174000" to avoid an INVALID_TIMESTAMP error.
          "fields": [
            {
              name: 'email',
              value: emailField,
            },
          ],
          "context": {
            // "hutk": hutk,
            "pageUri": window.location.href,
            "pageName": $('head title').text(),
            "ipAddress": window.userIP,
          },
        }),
      })
      .done(function() {
        form.find('input[type="email"]').val('');
        tyBox.removeClass('hidden');
      })
      .fail(function(e) {
        alert('Something went wrong, please conctact with website administrator');
        console.log(e);
      });
    }

    return false;
  });

  //more posts
  $(document).on('click', '#blog_loadmore', e => {
    const that = $(e.currentTarget);
    that.addClass('is-loading');

    $.ajax({
      url: '/wp-admin/admin-ajax.php',
      data: {
        'action': 'loadmorebutton',
        'query': blog_loadmore_params.posts,
        'page': blog_loadmore_params.current_page,
        'items_count': $('#blog_posts_wrap > div').length,
      },
      type: 'POST',
      beforeSend: function (xhr) {
        $('#blog_loadmore').addClass('is-active');
        $('#blog_filters').addClass('is-filtering');
        $('#blog_posts_wrap').addClass('is-filtering');
      },
      success: function (posts) {
        that.removeClass('is-loading');
        $('#blog_posts_wrap').removeClass('is-filtering');
        $('#blog_filters').removeClass('is-filtering');
        $('#blog_loadmore').removeClass('is-active');
        if (posts) {
          $('#blog_posts_wrap').append(posts);
          blog_loadmore_params.current_page++;
          if (blog_loadmore_params.current_page == blog_loadmore_params.max_page) {
            $('#blog_loadmore_wrap').addClass('is-hidden');
          }
        } else {
          $('#blog_loadmore_wrap').addClass('is-hidden');
        }
      },
    });
    return false;
  });

  //more releases
  $(document).on('click', '.js-releases-more', e => {
    const that = $(e.currentTarget);
    const nextPage = that.attr('data-page') * 1 + 1;
    const maxPages = that.attr('data-pages') * 1;

    that.attr('data-page', nextPage);

    $.ajax({
      url: '/wp-admin/admin-ajax.php',
      data: {
        'action': 'loadmorereleases',
        'page': nextPage,
      },

      type: 'POST',
      beforeSend: () => {
        that.addClass('is-loading');
      },

      success: posts => {
        that.removeClass('is-loading');

        if (posts) {
          $('.js-releases-loop').append(posts);
          if ( nextPage == maxPages ) {
            that.addClass('hidden');
          }
        } else {
          that.addClass('hidden');
        }
      },
    });

    return false;
  });

  //more webinars
  $(document).on('click', '.js-webinars-more', e => {
    const that = $(e.currentTarget);
    const nextPage = that.attr('data-page') * 1 + 1;
    const maxPages = that.attr('data-pages') * 1;

    that.attr('data-page', nextPage);

    $.ajax({
      url: '/wp-admin/admin-ajax.php',
      data: {
        'action': 'loadmorewebinars',
        'page': nextPage,
      },

      type: 'POST',
      beforeSend: () => {
        that.addClass('is-loading');
      },

      success: posts => {
        that.removeClass('is-loading');

        if (posts) {
          $('.js-webinars-loop').append(posts);
          if ( nextPage == maxPages ) {
            that.addClass('hidden');
          }
        } else {
          that.addClass('hidden');
        }
      },
    });

    return false;
  });

  //more media
  $(document).on('click', '.js-media-more', e => {
    const that = $(e.currentTarget);
    const nextPage = that.attr('data-page') * 1 + 1;
    const maxPages = that.attr('data-pages') * 1;

    that.attr('data-page', nextPage);

    $.ajax({
      url: '/wp-admin/admin-ajax.php',
      data: {
        'action': 'loadmoremedia',
        'page': nextPage,
      },

      type: 'POST',
      beforeSend: () => {
        that.addClass('is-loading');
      },

      success: posts => {
        that.removeClass('is-loading');

        if (posts) {
          $('.js-media-loop').append(posts);
          if ( nextPage == maxPages ) {
            that.addClass('hidden');
          }
        } else {
          that.addClass('hidden');
        }
      },
    });

    return false;
  });

  /*
    * Filter
    */
  $('#blog_filters').submit(function () {
    $.ajax({
      'url': '/wp-admin/admin-ajax.php',
      'data': $('#blog_filters').serialize(),
      'dataType': 'json',
      'type': 'POST',
      success: function (data) {
        blog_loadmore_params.current_page = 1;
        blog_loadmore_params.posts = data.posts;
        blog_loadmore_params.max_page = data.max_page;
        $('#blog_filters').removeClass('is-filtering');
        $('#blog_posts_wrap').removeClass('is-filtering');
        $('#blog_posts_wrap').html(data.content);
        if (data.max_page < 2) {
          $('#blog_loadmore_wrap').addClass('is-hidden');
        } else {
          $('#blog_loadmore_wrap').removeClass('is-hidden');
        }
      },
    });
    // do not submit the form
    return false;
  });

  $(document).on('change', '#blog_filters input[type="checkbox"]', function () {
    const thatVal = $(this).val();
    const checkboxesLength = $('#blog_filters input[type="checkbox"]').length;
    const notCheckedCheckboxesLength = $('#blog_filters input:checkbox:not(:checked)').length;

    if ( $(this).is(':checked') && thatVal == 'all' ) {
      $('#blog_filters input[type="checkbox"]').not('[value="all"]').prop('checked', false);
    }

    if ( $(this).is(':checked') && thatVal != 'all' ) {
      $('#blog_filters input[type="checkbox"][value="all"]').prop('checked', false);
    }

    if ( checkboxesLength == notCheckedCheckboxesLength ) {
      $('#blog_filters input[type="checkbox"][value="all"]').prop('checked', true);
    }

    $('#blog_filters').trigger('submit');
    $('#blog_filters').addClass('is-filtering');
    $('#blog_posts_wrap').addClass('is-filtering');
  });

  // const startTestimonialsScroll = () => {
  //   const windowWidth = $(window).width();

  //   $('.testimonials-2-row').each( (i, e) => {
  //     const that = $(e);
  //     const elWidth = that.find('li').width();
  //     const elCount = that.find('li').length;
  //     let rowWidth = 0;

  //     rowWidth = (elWidth * elCount) - 320 - windowWidth;

  //     that.attr('data-width', rowWidth);
  //     that.attr('data-count', elCount);

  //     that.attr('data-end-width', rowWidth);

  //     that.css('transform', `translateX(-${ rowWidth }px)`);
  //   });
  // };

  // const continueTestimonialsScroll = () => {
  //   console.log('continue');

  //   $('.testimonials-2-row').each( (i, e) => {
  //     const that = $(e);


  //     let rowWidth = that.attr('data-width') * 1;

  //     let endWidth = that.attr('data-end-width') * 1 + rowWidth;
  //     let elCount = that.attr('data-count') * 1;

  //     if ( that.hasClass('testimonials-2-row--2') || that.hasClass('testimonials-2-row--3') ) {
  //       that.find('li').clone().slice(1,elCount).addClass('clonned-el').appendTo(that);
  //     } else {
  //       that.find('li').clone().slice(0,elCount).addClass('clonned-el').appendTo(that);
  //     }

  //     that.attr('data-end-width', endWidth);

  //     that.css('transform', `translateX(-${ endWidth }px)`);
  //   });
  // };

  // if ( $('.testimonials-section-2').length && $(window).width() > 1199 ) {
  //   let testimonialsScroll = false;
  //   const windowHeight = $(window).height();
  //   const testimonialsOffset = $('.testimonials-section-2').offset().top;
  //   let interval = false;

  //   $(window).scroll(function() {
  //     let windowScroll = $(window).scrollTop() + windowHeight;

  //     if ( windowScroll > testimonialsOffset && !testimonialsScroll ) {
  //       startTestimonialsScroll();

  //       interval = setInterval(() => {
  //         continueTestimonialsScroll();

  //         console.log('tick');
  //       }, 15000);

  //       testimonialsScroll = true;
  //     }
  //   });
  // }

  $(document).on('keyup touch keypress', '.js-phone-number', function(e) {
    if (!String.fromCharCode(e.keyCode).match(/^[0-9-+()]*$/)) return false;
  });

  $(document).on('click', '.tp-widget-container', () => {
    window.dataLayer.push({
      'event': 'TrustPulseClick',
    });
  });

  //massonry
  if ( $('.js-massonry-testimonials').length ) {
    $('.js-massonry-testimonials').removeClass('hidden');
    let magicGrid = new MagicGrid({
      container: '.js-massonry-testimonials',
      animate: true,
      gutter: 30,
      // items: 6,
      static: true,
      useMin: true,
    });

    magicGrid.listen();
  }

  $(document).on('click', '.js-copy-to-clipboard', e => {
    const that = $(e.currentTarget);
    const thatTextElement = that.find('span');
    const thatText = thatTextElement.text();

    navigator.clipboard.writeText(that.closest('.container').find('.text').text());

    thatTextElement.text('Copied!');

    setTimeout(() => {
      thatTextElement.text(thatText);
    }, 4000);
  });

  //transcript scroll
  $(document).on('click', '.js-transcript-scroll', function (event) {
    const scrollBox = $('.transcript-wrap');
    const that = $(this);
    const url = that.attr('href');
    let hash = url.split('#')[1];
    hash = '#' + hash;
    const offset = scrollBox.scrollTop() + $(hash).offset().top - scrollBox.offset().top - 5;

    if ( $(hash).length ) {
      event.preventDefault();
      scrollBox.animate({
        scrollTop: offset,
      }, 500);
    }
  });

  $(document).on('click', '.widget_nav_menu h4', e => {
    const that = $(e.currentTarget);

    if ( $(window).width() < 576 ) {
      that.closest('.widget_nav_menu').toggleClass('is-active');
      that.closest('.widget_nav_menu').find('.menu').slideToggle();
    }
  });

  let hoverState = false;
  $('.b-cta-box-wrap').hover(e => {
    const that = $(e.currentTarget);
    if ( !hoverState && $(window).width() > 991 ) {
      that.addClass('is-active');
      that.find('.b-cta-box-bt').slideDown(400);
      hoverState = true;
    }
  }, e => {
    const that = $(e.currentTarget);
    let foundVal = false;

    that.find('form input[type="tel"], form input[type="text"], form input[type="email"]').each(function() {
      if ( !foundVal ) {
        foundVal = $(this).val() ? true : false;
      }
    });

    if ( hoverState && $(window).width() > 991 && !foundVal ) {
      that.removeClass('is-active');
      if ( !$('body').hasClass('logged-in') ) {
        that.find('.b-cta-box-bt').slideUp(400, () => {
          hoverState = false;
        });
      } else {
        hoverState = false;
      }
    }
  });

  $(document).on('click', '.left-box .btn', e => {
    const that = $(e.currentTarget);

    if ( $(window).width() < 992 && !$('body').hasClass('logged-in') ) {
      that.closest('.b-cta-box-wrap').toggleClass('is-active');
      $('.b-cta-box-bt').slideToggle();

      return false;
    }
  });

  //toggle text-block
  if ( $('.text-wrap--text .text-toggle').length ) {
    $('.text-wrap--text').each((i, e) => {
      const that = $(e);
      const thatBtn = that.find('.text-toggle');

      thatBtn.appendTo(that.find('p').last());
    });

    $(window).on('scroll', () => {
      const col = $('.content-section-14 .column-wrap').last();

      if ( $(window).scrollTop() + $(window).height() >= col.offset().top + col.height()) {
        col.addClass('is-active');
      }
    });
  }

  $(document).on('click', '.text-wrap--text .text-toggle', e => {
    const that = $(e.currentTarget);
    const thatParent = that.closest('.text-wrap--text');

    if ( thatParent.hasClass('is-active') ) {
      thatParent.removeClass('is-active');
      that.find('span').text('Show more');
    } else {
      thatParent.addClass('is-active');
      that.find('span').text('Show less');
    }
  });

  if ( $('.cta-section-blog--2').length && $(window).width() < 992 ) {
    $('.js-form-box-c7').insertAfter('.cta-section-blog--2');
  }

  if ( $('.sticky-d-btn').length ) {
    const btnHref = $('.sticky-d-btn .btn').attr('href');
    $(window).on('scroll', () => {
      if ( $(window).scrollTop() + $(window).height() - 30 > $(btnHref).offset().top ) {
        $('.sticky-d-btn').addClass('is-disabled');
      } else {
        $('.sticky-d-btn').removeClass('is-disabled');
      }
    });
  }

  //auto width for select
  $(document).on('change', '.js-select-current', e => {
    const that = $(e.currentTarget);

    that.closest('.js-select-wrap').find('.js-temp-option').html(that.find('option:selected').text());
    that.width(that.closest('.js-select-wrap').find('.js-select-tag-width').width() + 8);
  });

  $(document).on('click', 'a[href="#"]', () => {
    return false;
  });

  $(document).on('click', '.mobile-menu .menu-item-has-children > a', e => {
    const that = $(e.currentTarget);

    that.closest('.menu-item-has-children').toggleClass('is-active');
    that.siblings('.sub-menu').slideToggle();

    return false;
  });

  //podcasts load more
  $(document).on('click', '.js-more-podcasts', e => {
    const that = $(e.currentTarget);
    let currentPage = '';
    currentPage = that.attr('data-page');
    const maxPages = that.attr('data-pages');
    const term = that.attr('data-term');
    let nextPage = currentPage * 1 + 1;

    $.ajax({
      url: '/wp-admin/admin-ajax.php',
      data: {
        'action': 'podcast_loadmorebutton',
        'page': currentPage,
        'term': term,
      },
      type: 'POST',
      beforeSend: function () {
        $('.js-more-podcasts').addClass('is-loading');
        $('.js-podcasts-loop').addClass('is-filtering');
      },
      success: function (data) {
        $('.js-more-podcasts').removeClass('is-loading');
        $('.js-podcasts-loop').removeClass('is-filtering');

        if (data) {
          const parsedData = JSON.parse(data);

          that.attr('data-pages', parsedData.max_page);

          $('.js-podcasts-loop').append(parsedData.content);
          // podcast_loadmore_params.current_page++;
          if (nextPage == maxPages) {
            $('.js-more-podcasts').hide();
          }
        } else {
          $('.js-more-podcasts').hide();
        }
        that.attr('data-page', nextPage);

        // that.attr('data-page', nextPage);
      },
    });
    return false;
  });

  $(document).on('change', '.js-season-select', e => {
    const that = $(e.currentTarget);
    const thatVal = that.val();

    $('.js-more-podcasts').attr('data-page', '1');
    $('.js-more-podcasts').attr('data-term', thatVal);

    $.ajax({
      url: '/wp-admin/admin-ajax.php',
      data: {
        'action': 'podcast_loadmorebutton',
        'page': 0,
        'term': thatVal,
      },
      type: 'POST',
      beforeSend: function () {
        $('.js-more-podcasts').addClass('is-loading');
        $('.js-podcasts-loop').addClass('is-filtering');
      },
      success: function (data) {
        $('.js-more-podcasts').removeClass('is-loading');
        $('.js-podcasts-loop').removeClass('is-filtering');

        if (data) {
          const parsedData = JSON.parse(data);

          if ( parsedData.max_page > 1 ) {
            $('.js-more-podcasts').attr('data-pages', parsedData.max_page);
            $('.js-more-podcasts').show();
          } else {
            $('.js-more-podcasts').hide();
          }

          $('.js-podcasts-loop').html(parsedData.content);
        } else {
          $('.js-more-podcasts').hide();
        }
      },
    });
  });

  $(document).on('click', '.faq-group__head', e => {
    const that = $(e.currentTarget);

    that.toggleClass('is-active');
    that.siblings('.faq-group__body').slideToggle();
  });
});
